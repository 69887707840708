import { BsFillAwardFill } from 'react-icons/bs';
const Partnership = () => {
    return (
        <div className="partnership" id="partnership">
            <div className="container">
                <h4 style={{ fontSize: "55px", color: "white" }}>Partnership</h4>
                <div className="list-patner">
                    <div className="patner-item">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/2/20/FPT_Polytechnic.png" alt="" />
                    </div>
                    <div className="patner-item">
                        <img src="https://img5.thuthuatphanmem.vn/uploads/2022/01/16/logo-fpt-university_043151936.png" alt="" />
                    </div>
                    <div className="patner-item">
                        <img src="https://img5.thuthuatphanmem.vn/uploads/2022/01/16/logo-fpt-fpt-polytechnic-tach-nen-dep_043151037.png" alt="" />
                    </div>
                    {/* <div className="patner-item">
                        <img src="https://herofi.b-cdn.net/wp-content/uploads/2021/10/logo-bbg.png" alt="" />
                    </div>
                    <div className="patner-item">
                        <img src="https://herofi.b-cdn.net/wp-content/uploads/2021/10/logo-shibance.png" alt="" />
                    </div>
                    <div className="patner-item">
                        <img src="https://herofi.b-cdn.net/wp-content/uploads/2021/10/logo-curanetwork.png" alt="" />
                    </div>
                    <div className="patner-item">
                        <img src="https://herofi.b-cdn.net/wp-content/uploads/2021/11/GAIGANGS_%C2%A1%C2%A6s-1-1024x375.png" alt="" />
                    </div>
                    <div className="patner-item">
                        <img src="https://herofi.b-cdn.net/wp-content/uploads/2021/11/Chainlink-Logo-Blue.png" alt="" />
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Partnership;