
import { useEffect } from "react";
import Banner from "./layout/Banner";
import Infomation from "./layout/Infomation";
import Footer from "./layout/Footer";
import Header from "./layout/Header";
import Partnership from "./layout/Partnership";
import Content from "./layout/Content";
import ForumHome from "./layout/ForumHome";
import PageFB from "./layout/PageFB";

const Home = () => {
    useEffect(() => {
        document.title = "CLB MC-FPOLY";
    }, [])

    return (
        <>
            <Header />
            <Banner />
            <Infomation />
            {/* <PageFB /> */}
            {/* <Content /> */}
            {/* <ForumHome /> */}
            <Partnership />
            <Footer />
        </>
    )
}

export default Home;