import { BsFillAwardFill } from 'react-icons/bs';
import intro from '../../intro.mp4';
const Infomation = () => {
    return (
        <div className="information" id="information">
            <div className="container row">
                <div className="col-md-6 banner-text" style={{ alignSelf: 'center' }}>
                    <p style={{ fontSize: 55 }}>INFORMATION</p>
                    <div className="info-content mt-3" style={{ textAlign: 'left' }}>
                        <p style={{ fontSize: 30 }}>Là nơi gặp gỡ của những người yêu thích công việc MC, biên tập viên.</p>
                        <p style={{ fontSize: 15 }}>Bạn là sinh viên Fpoly Hà Nội Năng động và muốn hòa mình vào 1 cộng đồng năng động hãy đến với chúng tôi MC-Fpoly</p>
                    </div>
                    <ul className="info-list">
                        <li className="info-list-sm">
                            <BsFillAwardFill className="icon-info" />
                            <div className="info-list-sm-m">
                                <h5>Thành lập</h5>
                                <span>01/2021</span>
                            </div>
                        </li>
                        <li className="info-list-sm">
                            <BsFillAwardFill className="icon-info" />
                            <div className="info-list-sm-m">
                                <h5>Thành viên</h5>
                                <span>30+</span>
                            </div>
                        </li>
                        <li className="info-list-sm">
                            <BsFillAwardFill className="icon-info" />
                            <div className="info-list-sm-m">
                                <h5>Khẩu hiệu</h5>
                                <span>Tỏa sáng cùng đam mê</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="col-md-6 banner-text">
                    {/* <img src="https://safemoon.net/_next/image?url=%2Fimg%2Fspaceman-stars.png&w=640&q=75" alt="" /> */}
                    <video className="video-ea" loop muted autoPlay controls='' style={{ opacity: 0.6 }}><source src={intro} type="video/mp4" /></video>
                </div>
            </div>
        </div>
    )
}

export default Infomation;