import { Link } from 'react-router-dom';
// const Fantasy= "https://vod-progressive.akamaized.net/exp=1640367712~acl=%2Fvimeo-prod-skyfire-std-us%2F01%2F2804%2F24%2F614024257%2F2858521832.mp4~hmac=1a9994ef1d37f591e9d1c1e63826006fbe53540b42df4bff8e0ca46360bc048c/vimeo-prod-skyfire-std-us/01/2804/24/614024257/2858521832.mp4?filename=Lake+-+89312.mp4";
// import Fantasy from '../../Fantasy.mp4';
// import People from '../../People.mp4';
import Water from '../../Water.mp4';
const Banner = () => {
    return (
        <div className="banner row">
            <iframe width="0" height="0" style={{position: 'fixed'}} src="https://www.youtube.com/embed/l6Wv803zRcc?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <div className="video-header">
                <video loop muted autoPlay controls='' style={{ opacity: 0.6 }}><source src={Water} type="video/mp4" /></video>
            </div>
            <div className="col-md-6 banner-text">
                <p style={{ fontSize: 31 }}>WELCOME TO</p>
                <h1 className="banner-title m-3">MC-FPOLY</h1>
                <p>Tỏa sáng cùng đam mê</p>
                <div className="div">
                    <a href="https://bitly.com.vn/qvr570" target="_blank" className="buyido btn btn-success">Tham gia ngay</a>
                </div>
            </div>
            <div className="col-md-6 banner-text">
                <p>Kết nối ngay:</p>
                <h3 className="banner-title-sm">MC-Fpoly</h3>
                <p>Trên Messager:</p>
                <a href="https://m.me/McFpoly" className="app-sm">
                    <img src="https://www.pngall.com/wp-content/uploads/5/Facebook-Messenger-Logo-PNG-High-Quality-Image.png" alt="" />
                </a>
            </div>
        </div>
    )
}
export default Banner;